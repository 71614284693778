import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tz"]

  initialize() {
    if (this.tzTarget.innerText === "") return

    let date = new Date(this.tzTarget.innerText)
    this.tzTarget.innerText = date.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  }
}
