import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "draftSubject",
    "draftBody",
    "templateSubject",
    "templateBody",
    "emailTemplate",
  ]

  handleChange(event) {
    event.preventDefault()
    const selectedValue = event.target.value
    if (selectedValue === "template") {
      this.draftSubjectTarget.value = this.templateSubjectTarget.value
      this.draftBodyTarget.value = this.templateBodyTarget.value
    }
  }
}
