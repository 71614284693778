import { Controller } from "@hotwired/stimulus"
import DirectUploadController from "../controllers/direct_upload_controller.js"

// Connects to data-controller="instant-uploader"
export default class extends Controller {
  static targets = ["input", "form"]
  static values = { url: String }

  chooseUpload(e) {
    e.preventDefault()
    const file = e.srcElement.files[0]
    this.setIsUploading()

    setTimeout(() => {
      new DirectUploadController(this, file).start()
    }, 500)
  }

  onError() {
    this.removeIsUploading()
  }

  onSuccess() {
    this.formTarget.requestSubmit()
    this.removeIsUploading()
  }

  setIsUploading() {
    this.inputTarget.classList.add("is-uploading")
    this.inputTarget.classList.add("opacity-50")
  }

  removeIsUploading() {
    this.inputTarget.classList.remove("opacity-50")
    this.inputTarget.classList.remove("is-uploading")
  }
}
