import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hamburger-panel"
export default class extends Controller {
  static targets = ["panel"]

  close() {
    this.panelTarget.classList.add("hidden")
  }

  open() {
    this.panelTarget.classList.remove("hidden")
  }
}
