import { DirectUpload } from "@rails/activestorage"
import { Controller } from "@hotwired/stimulus"
import { removeElement, insertAfter } from "../helpers"

export default class extends Controller {
  constructor(source, file) {
    super()
    this.directUpload = new DirectUpload(file, source.urlValue, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()

    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.source.onError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.source.inputTarget.name = ""
        this.source.onSuccess()
      }
    })
  }

  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event),
    )
  }

  uploadRequestDidProgress(event) {
    const progress = parseInt((event.loaded / event.total) * 100)

    if (this.source.hasProgressWrapTarget) {
      this.source.progressWrapTarget.classList.remove("hidden")
    }

    if (this.source.hasProgressNumberTarget) {
      this.source.progressNumberTarget.innerHTML = `${progress}`
    }

    if (this.source.hasProgressTarget) {
      this.source.progressTarget.style.width = `${progress}%`
    }
  }
}
