import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-on-change"
export default class extends Controller {
  static targets = ["form", "labelTrue", "labelFalse"]

  submitForm(event) {
    this.formTarget.requestSubmit()
    if (event.target.type == "checkbox") {
      if (event.target.checked) {
        this.labelTrueTarget.classList.remove("hidden")
        this.labelFalseTarget.classList.add("hidden")
      } else {
        this.labelTrueTarget.classList.add("hidden")
        this.labelFalseTarget.classList.remove("hidden")
      }
    }
  }
}
