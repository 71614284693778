import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ["loading", "refresh"]

  refresh() {
    // leaving commented out until we implement polling
    // this.loadingTarget.classList.remove("hidden")
    // this.refreshTarget.classList.add("hidden")
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
}
