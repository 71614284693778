import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications-polling"
export default class extends Controller {
  static values = { timeoutId: Number }

  connect() {
    this.timeoutIdValue = setTimeout(() => {
      fetch("/notifications", {
        headers: { Accept: "text/vnd.turbo-stream.html" },
      })
        .then((response) => response.text())
        .then((html) => Turbo.renderStreamMessage(html))
    }, 3000)
  }

  disconnect() {
    clearTimeout(this.timeoutIdValue)
  }
}
