import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = [
    "emailBody",
    "emailSubject",
    "referrerNotes",
    "ownerNotes",
    "sendToEmail",
    "notes",
    "toggleText",
    "caretUp",
    "caretDown",
  ]

  connect() {
    this.showValue = true
  }

  showHideNotes(event) {
    event.preventDefault()
    const isNotesDisplayed = this.showValue
    this.showValue = !isNotesDisplayed
    this.toggleNotes(!isNotesDisplayed)
    this.toggleTextTarget.textContent = isNotesDisplayed ? "Show" : "Hide"
  }

  toggleNotes(isDisplayed) {
    if (isDisplayed) {
      this.notesTarget.classList.remove("hidden")
      this.caretUpTarget.classList.remove("hidden")
      this.caretDownTarget.classList.add("hidden")
    } else {
      this.notesTarget.classList.add("hidden")
      this.caretUpTarget.classList.add("hidden")
      this.caretDownTarget.classList.remove("hidden")
    }
  }

  handleSave(event) {
    event.preventDefault()

    let data = {}
    if (this.hasOwnerNotesTarget) data.owner_notes = this.ownerNotesTarget.value
    if (this.hasReferrerNotesTarget)
      data.referrer_notes = this.referrerNotesTarget.value
    if (this.hasEmailSubjectTarget) {
      data.email_subject = this.emailSubjectTarget.value
      data.email_body = this.emailBodyTarget.value
      data.send_to_email = this.sendToEmailTarget.value
    }

    this.sendData({ note: data })
  }

  sendData(data) {
    fetch(this.urlValue, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getAuthenticityToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(data),
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) {
          console.error({ response })
        }
        window.location.reload()
      })
      .catch((error) => console.error("Error:", error))
  }

  getAuthenticityToken() {
    // nil check for test env
    return document
      ?.querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content")
  }
}
