import { Application } from "@hotwired/stimulus"

const application = Application.start()

import { Alert, Dropdown, Popover, Tabs } from "tailwindcss-stimulus-components"
application.register("alert", Alert)
application.register("dropdown", Dropdown)
application.register("popover", Popover)
application.register("tabs", Tabs)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
