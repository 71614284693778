import { Controller } from "@hotwired/stimulus"
import DirectUploadController from "../controllers/direct_upload_controller.js"

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = [
    "cancelButton",
    "dragzone",
    "form",
    "input",
    "fileDisplay",
    "fileName",
    "fileType",
    "fileSize",
    "errorMessageWrap",
    "errorMessage",
    "progress",
    "progressWrap",
    "progressNumber",
  ]

  static values = {
    url: String,
    accept: Array,
  }

  onDragEnter(e) {
    e.preventDefault()
    this.addUploadingStyles()
  }

  onDragOver(e) {
    e.preventDefault()
    this.addUploadingStyles()
  }

  onDragLeave(e) {
    e.preventDefault()
    this.removeUploadingStyles()
  }

  dropUpload(e) {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    this.uploadFile(file)
    this.removeUploadingStyles()
  }

  chooseUpload(e) {
    e.preventDefault()
    const file = e.srcElement.files[0]
    this.uploadFile(file)
  }

  uploadFile(file) {
    this.hideError()

    if (this.isValidFileType(file)) {
      this.setFileInfo(file)
      setTimeout(() => {
        new DirectUploadController(this, file).start()
      }, 500)
    } else {
      console.log(this.acceptValue)
      console.log(file.type)
      this.showError(
        "We don't recognize the file type. Please double check your file and re-upload it.",
      )
    }
  }

  setFileInfo(file) {
    let fileType = "File type: " + file.type
    if (file.type.includes("csv")) {
      fileType = "CSV documnet"
    } else if (file.type.includes("zip")) {
      fileType = "ZIP file"
    }
    this.fileNameTarget.innerHTML = file.name

    this.fileTypeTarget.innerHTML = fileType
    this.fileSizeTarget.innerHTML =
      Math.round(file.size / 1000).toString() + " Kb"
    this.fileDisplayTarget.classList.remove("hidden")
    this.dragzoneTarget.classList.add("hidden")
  }

  onError(error) {
    this.showError(error)
  }

  onSuccess() {
    this.formTarget.submit()
  }

  addUploadingStyles() {
    this.dragzoneTarget.classList.remove("border-dashed")
    this.dragzoneTarget.classList.add("border-solid")
  }

  removeUploadingStyles() {
    this.dragzoneTarget.classList.remove("border-solid")
    this.dragzoneTarget.classList.add("border-dashed")
  }

  isValidFileType(file) {
    return this.acceptValue.includes(file.type)
  }

  showError(error) {
    this.errorMessageWrapTarget.classList.remove("hidden")
    this.errorMessageTarget.innerHTML = error
  }

  hideError() {
    this.errorMessageWrapTarget.classList.add("hidden")
  }
}
