import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["state", "owner", "currentOwner", "effectiveOwner"]

  connect() {
    this.toggleBlankOption()
    this.selectOwner()
  }

  handleStateChange() {
    if (this.hasStateTarget) {
      this.selectOwner()
      this.toggleBlankOption()
    }
  }

  selectOwner() {
    if (this.ownerTarget.value == "") {
      this.selectOption()
    }
  }

  toggleBlankOption() {
    const state = this.stateTarget.value
    if (["suggested", "offered"].includes(state)) {
      if (!this.hasBlankOption()) {
        const blankOption = new Option("None", "", false, false)
        this.ownerTarget.prepend(blankOption)
      }
    } else {
      if (this.hasBlankOption()) {
        this.ownerTarget.options[0].remove()
      }
    }
  }

  hasBlankOption() {
    return this.ownerTarget.options[0].value == ""
  }

  selectOption() {
    const effectiveOwnerValue = this.effectiveOwnerTarget.value
    const options = this.ownerTarget.options

    for (let i = 0; i < options.length; i++) {
      if (options[i].value === effectiveOwnerValue) {
        options[i].selected = true
        break
      }
    }
  }
}
