import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="open-mailto"
export default class extends Controller {
  static values = {
    url: String,
  }

  static targets = ["email", "subject", "body", "baseUrl", "defaultUrl"]

  open(e) {
    e.preventDefault()
    window.open(this.urlValue, "_blank")
  }

  openCustom(e) {
    e.preventDefault()
    if (
      this.isNotEmpty("email") ||
      this.isNotEmpty("subject") ||
      this.isNotEmpty("body")
    ) {
      let mailUrl = `${this.baseUrlTarget.value}`
      if (this.hasEmailTarget && this.emailTarget.value !== "") {
        mailUrl += `&to=${encodeURIComponent(this.emailTarget.value)}`
      }
      if (this.subjectTarget.value !== "") {
        mailUrl += `&${this.subjectText()}=${encodeURIComponent(
          this.subjectTarget.value,
        )}`
      }
      if (this.bodyTarget.value !== "") {
        mailUrl += `&body=${encodeURIComponent(this.bodyTarget.value)}`
      }

      window.open(mailUrl, "_blank")
    } else {
      window.open(this.defaultUrlTarget.value, "_blank")
    }
  }

  isNotEmpty(target) {
    if (
      this["has" + target.charAt(0).toUpperCase() + target.slice(1) + "Target"]
    ) {
      return this[target + "Target"].value !== ""
    }
    return false
  }

  subjectText() {
    if (this.baseUrlTarget?.value?.includes("mailto")) {
      return "subject"
    } else {
      return "su"
    }
  }
}
