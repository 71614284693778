import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contactEmail", "workEmail"]

  connect() {
    if (
      this.hasContactEmailTarget &&
      (!this.workEmailTarget?.value || this.workEmailTarget?.value === "")
    ) {
      this.workEmailTarget.value = this.contactEmailTarget.value
    }
  }
}
