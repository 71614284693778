import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-keyword"
export default class BulkKeywordController extends Controller {
  static targets = [
    "function",
    "functionTab",
    "seniority",
    "seniorityTab",
    "omitted",
    "omittedTab",
  ]
  static classes = ["selected", "unselected"]

  declare readonly functionTarget: HTMLElement
  declare readonly seniorityTarget: HTMLElement
  declare readonly omittedTarget: HTMLElement
  declare readonly functionTabTarget: HTMLElement
  declare readonly seniorityTabTarget: HTMLElement
  declare readonly omittedTabTarget: HTMLElement

  declare readonly selectedClasses: string[]
  declare readonly unselectedClasses: string[]

  setUnselected(tab: HTMLElement, content: HTMLElement) {
    tab.classList.remove(...this.selectedClasses)
    tab.classList.add(...this.unselectedClasses)
    content.hidden = true
  }

  setSelected(tab: HTMLElement, content: HTMLElement) {
    tab.classList.add(...this.selectedClasses)
    tab.classList.remove(...this.unselectedClasses)
    content.hidden = false
  }

  select_seniority() {
    this.setSelected(this.seniorityTabTarget, this.seniorityTarget)
    this.setUnselected(this.functionTabTarget, this.functionTarget)
    this.setUnselected(this.omittedTabTarget, this.omittedTarget)
  }

  select_function() {
    this.setUnselected(this.seniorityTabTarget, this.seniorityTarget)
    this.setSelected(this.functionTabTarget, this.functionTarget)
    this.setUnselected(this.omittedTabTarget, this.omittedTarget)
  }

  select_omitted() {
    this.setUnselected(this.seniorityTabTarget, this.seniorityTarget)
    this.setUnselected(this.functionTabTarget, this.functionTarget)
    this.setSelected(this.omittedTabTarget, this.omittedTarget)
  }
}
