import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "leaderInput",
    "referrerInput",
    "canAccessTeamSettingsToggle",
  ]

  static values = {
    manageDefaultTeamSettings: Boolean,
  }

  toggle(event) {
    this.referrerInputTarget.classList.add("hidden")
    this.leaderInputTarget.classList.add("hidden")

    if (event.target.value === "referrer") {
      this.referrerInputTarget.classList.remove("hidden")

      if (this.manageDefaultTeamSettingsValue) {
        this.canAccessTeamSettingsToggleTarget.checked = false
      }
    } else if (event.target.value === "leader") {
      this.leaderInputTarget.classList.remove("hidden")

      if (this.manageDefaultTeamSettingsValue) {
        this.canAccessTeamSettingsToggleTarget.checked = true
      }
    } else if (this.manageDefaultTeamSettingsValue) {
      this.canAccessTeamSettingsToggleTarget.checked = false
    }
  }
}
