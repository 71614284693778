import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="focus-on-connect"
export default class extends Controller {
  static targets = ["focus"]

  connect() {
    this.focusTarget.focus()
  }
}
