import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="ts-select"
export default class extends Controller {
  static values = { url: String, canCreate: Boolean }
  static targets = ["input"]

  connect() {
    let config = {
      plugins: ["remove_button"],
      create: this.canCreateValue,
    }

    if (this.urlValue) {
      config["valueField"] = "value"
      config["load"] = (q, callback) => {
        this.search(q, callback)
      }
    }

    new TomSelect(this.element, config)
  }

  async search(q, callback) {
    const url = this.urlValue + "?q=" + encodeURIComponent(q)
    const response = await fetch(url, {
      responseKind: "json",
    })

    if (response.ok) {
      const list = await response.json()
      callback(list)
    } else {
      callback()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape" && this.inputTarget.selectedIndex >= 0) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }
}
